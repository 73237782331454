import React, { useMemo } from 'react'
import { Drawer } from '@mui/material'
import { Button, Col, Row, Tabs, Typography } from 'antd'
import { Match, MatchWithName, Team } from '../../models/types'
import { useMobile } from '../../services/hooks/layout.hooks'
import { GoogleCalendarExport } from './ExportMethods/GoogleCalendarExport'
import { OutlookCalendarExport } from './ExportMethods/OutlookCalendarExport'
import { SMSExport } from './ExportMethods/SMSExport'
import { ShareExport } from './ExportMethods/ShareExport'
import { createMatchWithName } from '../../utils/match'

const { Title } = Typography

interface ExportProps {
  selectedMatchIdsMappedToMatch?: Map<string, Match>
  teamIdToTeamMap?: Map<string, Team>
  selectedMatchesWithNames?: MatchWithName[]
  showExportDrawer: boolean
  onBack: () => void
  onClose: () => void
}

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as React.CSSProperties,
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#eeeeee',
    padding: '12px',
    borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
    borderTop: '1px solid rgba(140, 140, 140, 0.35)',
    height: '80px',
  } as React.CSSProperties,
  closeButton: {},
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  drawerContent: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as React.CSSProperties,
}

export const Export = ({
  selectedMatchesWithNames,
  selectedMatchIdsMappedToMatch,
  teamIdToTeamMap,
  showExportDrawer,
  onBack,
  onClose,
}: ExportProps) => {
  const isMobile = useMobile()

  const matchesWithNames: MatchWithName[] = useMemo(() => {
    if (selectedMatchesWithNames) {
      return selectedMatchesWithNames
    }
    if (selectedMatchIdsMappedToMatch) {
      return Array.from(selectedMatchIdsMappedToMatch.values()).map(
        (match: Match) => createMatchWithName(match, teamIdToTeamMap)
      )
    }
    return []
  }, [selectedMatchesWithNames, selectedMatchIdsMappedToMatch, teamIdToTeamMap])

  const tabItems = [
    {
      key: '1',
      label: 'Google Calendar',
      children: <GoogleCalendarExport selectedMatchWithNames={matchesWithNames} />,
    },
    {
      key: '2',
      label: 'Outlook Calendar',
      children: <OutlookCalendarExport selectedMatchWithNames={matchesWithNames} />,
    },
    {
      key: '3',
      label: 'SMS',
      children: <SMSExport selectedMatchWithNames={matchesWithNames} />,
    },
    {
      key: '4',
      label: 'Share',
      children: <ShareExport selectedMatchWithNames={matchesWithNames} />,
    },
  ]

  return (
    <>
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={showExportDrawer}
        onClose={onClose}
        style={{ height: '100vh' }}
      >
        <div style={classes.root}>
          <Row style={classes.closeButton}>
            <Col span={24} style={classes.closeButtonContainer}>
              <Button type="text" onClick={onBack}>
                Back
              </Button>
              <Button type="text" onClick={onClose}>
                Close
              </Button>
            </Col>
          </Row>
          <Row style={classes.header}>
            <Col span={24}>
              <Title level={2}>Export</Title>
            </Col>
          </Row>
          <div style={classes.drawerContent}>
            <Tabs
              defaultActiveKey="1"
              style={{ height: '100%' }}
              items={tabItems}
            />
          </div>
        </div>
      </Drawer>
    </>
  )
}
