import { Tournament } from '../models/types'

export const sortTournaments = (
  tournament1: Tournament,
  tournament2: Tournament
): number => {
  if (tournament1.name < tournament2.name) {
    return -1
  } else if (tournament1.name > tournament2.name) {
    return 1
  }
  return 0
}
