import React, { useState } from 'react'
import { Button, Col, Row, Tabs, Typography } from 'antd'
import { useDesktop, useTablet } from '../../services/hooks/layout.hooks'
import { FilterTab } from '../../models/enums'
import { Drawer } from '@mui/material'

const { Title } = Typography

interface FilterWrapperProps {
  tournamentList: JSX.Element
  teamsList: JSX.Element
  currentTab: FilterTab
  showFilterDrawer: boolean
  onMobileFilter: () => void
  onSelectAllTournaments: () => void
  onClearAllTournaments: () => void
  onSelectAllTeams: () => void
  onClearAllTeams: () => void
}

const classes = {
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 16px 0 16px',
  },
  drawerContent: {
    padding: '0 16px 16px 16px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as React.CSSProperties,
  filterButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '4px',
    gap: '8px',
    marginBottom: '16px',
  },
}

export const FilterWrapper = ({
  tournamentList,
  teamsList,
  currentTab,
  showFilterDrawer,
  onMobileFilter,
  onSelectAllTournaments,
  onClearAllTournaments,
  onSelectAllTeams,
  onClearAllTeams,
}: FilterWrapperProps) => {
  const isTablet = useTablet()
  const isDesktop = useDesktop()

  const [currentMobileTab, SetCurrentMobileTab] = useState<FilterTab>(
    FilterTab.TOURNAMENT
  )

  if (isTablet) {
    return (
      <Col span={8}>
        {currentTab === FilterTab.TOURNAMENT ? tournamentList : teamsList}
      </Col>
    )
  }

  if (isDesktop) {
    return (
      <>
        <Col span={6}>{tournamentList}</Col>
        <Col span={6}>{teamsList}</Col>
      </>
    )
  }

  // Define the items for Tabs
  const tabItems = [
    {
      key: FilterTab.TOURNAMENT,
      label: 'Tournaments',
      children: (
        <>
          <Row>
            <Col span={24} style={classes.filterButtons}>
              <Button
                size="small"
                type="primary"
                onClick={onSelectAllTournaments}
              >
                Select All
              </Button>
              <Button size="small" onClick={onClearAllTournaments}>
                Clear
              </Button>
            </Col>
          </Row>
          {tournamentList}
        </>
      ),
    },
    {
      key: FilterTab.TEAM,
      label: 'Teams',
      children: (
        <>
          <Row>
            <Col span={24} style={classes.filterButtons}>
              <Button size="small" type="primary" onClick={onSelectAllTeams}>
                Select All
              </Button>
              <Button size="small" onClick={onClearAllTeams}>
                Clear
              </Button>
            </Col>
          </Row>
          {teamsList}
        </>
      ),
    },
  ]

  return (
    <Drawer anchor="bottom" open={showFilterDrawer} onClose={onMobileFilter}>
      <Row>
        <Col span={24} style={classes.closeButtonContainer}>
          <Title level={4}>Filter</Title>
          <Button type="text" onClick={onMobileFilter}>
            Close
          </Button>
        </Col>
      </Row>
      <div style={classes.drawerContent}>
        <Tabs
          defaultActiveKey={FilterTab.TOURNAMENT}
          style={{ height: '100%' }}
          activeKey={currentMobileTab}
          onChange={(e: string) => SetCurrentMobileTab(e as FilterTab)}
          items={tabItems}
        />
      </div>
    </Drawer>
  )
}
