import React from 'react'
import { Button, Col, Row, Tabs } from 'antd'
import { FilterTab } from '../../models/enums'

interface TabletFilterHeaderProps {
  onSelectAllTournaments: () => void
  onClearAllTournaments: () => void
  onSelectAllTeams: () => void
  onClearAllTeams: () => void
  onFilterTabChange: (tab: FilterTab) => void
}

const classes = {
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '4px',
    gap: '8px',
  },
  tabletFilterHeader: {
    padding: '0 12px 12px 12px',
    height: '105px',
  },
}

export const TabletFilterHeader = ({
  onSelectAllTournaments,
  onClearAllTournaments,
  onSelectAllTeams,
  onClearAllTeams,
  onFilterTabChange,
}: TabletFilterHeaderProps) => {
  const items = [
    {
      key: FilterTab.TOURNAMENT,
      label: "Filter Tournaments",
      children: (
        <Row>
          <Col span={24} style={classes.buttons}>
            <Button
              size="small"
              type="primary"
              onClick={onSelectAllTournaments}
            >
              Select All
            </Button>
            <Button size="small" onClick={onClearAllTournaments}>
              Clear
            </Button>
          </Col>
        </Row>
      ),
    },
    {
      key: FilterTab.TEAM,
      label: "Filter Teams",
      children: (
        <Row>
          <Col span={24} style={classes.buttons}>
            <Button size="small" type="primary" onClick={onSelectAllTeams}>
              Select All
            </Button>
            <Button size="small" onClick={onClearAllTeams}>
              Clear
            </Button>
          </Col>
        </Row>
      ),
    },
  ]

  return (
    <Col span={8} className="filter-border" style={classes.tabletFilterHeader}>
      <Tabs
        defaultActiveKey="1"
        centered
        items={items}
        onChange={(e: string) => onFilterTabChange(e as FilterTab)}
      />
    </Col>
  )
}
