import React, { useEffect, useMemo, useState } from 'react'
import { Empty, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMobile, useTablet } from '../../services/hooks/layout.hooks'
import { TeamsForTournament } from './components/TeamsForTournament'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'
import { LogoResponse, Team } from '../../models/types'
import { NO_REGION } from '../../models/constants'

interface TeamsListProps {
  teamIdToTeamMap?: Map<string, Team>
  isLoading: boolean
  selectedTeamIds?: Set<string>
  setSelectedTeamIds: (teams: Set<string>) => void
  isTeamImagesLoading: boolean
  mapTeamNameToImage?: LogoResponse
}
interface RegionTeams {
  region: string
  teamList: Team[]
}

export const TeamsList = ({
  teamIdToTeamMap,
  isLoading,
  selectedTeamIds,
  setSelectedTeamIds,
  isTeamImagesLoading,
  mapTeamNameToImage,
}: TeamsListProps) => {
  const isTablet = useTablet()
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()
  const [numTeams, setNumTeams] = useState<number>(0)

  // Group teams by region
  const teamDataByRegion: RegionTeams[] | undefined = useMemo(() => {
    if (teamIdToTeamMap) {
      const teamIdsByRegion = new Map<string, Set<string>>()
      const teamsByRegion = new Map<string, Team[]>()

      Array.from(teamIdToTeamMap.values()).forEach((team) => {
        if (!team.region) {
          team.region = NO_REGION
        }
        if (!teamIdsByRegion.has(team.region)) {
          teamIdsByRegion.set(team.region, new Set<string>())
          teamsByRegion.set(team.region, [])
        }

        if (
          teamIdsByRegion.get(team.region) &&
          !teamIdsByRegion.get(team.region)!.has(team.id)
        ) {
          teamIdsByRegion.get(team.region)!.add(team.id)
          teamsByRegion.set(team.region, [
            ...teamsByRegion.get(team.region)!,
            team,
          ])
        }
      })

      teamsByRegion.forEach((teamList: Team[]) => {
        teamList.sort((a, b) => a.name.localeCompare(b.name))
      })

      return Array.from(teamsByRegion.entries())
        .map(
          ([region, teamList]: [string, Team[]]) =>
            ({ region, teamList } as RegionTeams)
        )
        .sort((regionTeam1, regionTeam2) => {
          if (regionTeam1.region === NO_REGION) return 1
          if (regionTeam2.region === NO_REGION) return -1
          return regionTeam1.region.localeCompare(regionTeam2.region)
        })
    }
  }, [teamIdToTeamMap])

  useEffect(() => {
    if (teamDataByRegion && teamDataByRegion.length > 0) {
      const totalTeams = teamDataByRegion.reduce(
        (total, regionTeam) => total + regionTeam.teamList.length,
        0
      )
      setNumTeams(totalTeams)
    }
  }, [teamDataByRegion])

  const handleTeamSelected = (teamId: string): void => {
    googleAnalytics.log('team.filtered_' + teamId)
    if (selectedTeamIds && !selectedTeamIds.has(teamId)) {
      setSelectedTeamIds(new Set(selectedTeamIds.add(teamId)))
    } else {
      const filteredStateArr = Array.from(selectedTeamIds || []).filter(
        (val) => val !== teamId
      )
      setSelectedTeamIds(new Set(filteredStateArr))
    }
  }

  return (
    <>
      {!isLoading ? (
        <>
          {numTeams > 0 && teamDataByRegion && teamDataByRegion.length > 0 ? (
            <div
              id="scrollableTeamDiv"
              className={
                isTablet
                  ? 'tablet-scroll-section'
                  : isMobile
                  ? 'mobile-scroll-section'
                  : 'scroll-section'
              }
            >
              <InfiniteScroll
                dataLength={numTeams}
                next={() => {}}
                hasMore={false}
                loader={
                  <div style={{ padding: '16px' }}>
                    <Skeleton avatar paragraph={{ rows: 1 }} active />
                  </div>
                }
                scrollableTarget="scrollableTeamDiv"
              >
                {teamDataByRegion.map((regionTeams: RegionTeams) => (
                  <TeamsForTournament
                    key={regionTeams.region}
                    teamList={regionTeams.teamList}
                    tournamentName={regionTeams.region}
                    selectedTeamIds={selectedTeamIds}
                    isTeamImagesLoading={isTeamImagesLoading}
                    mapTeamNameToImage={mapTeamNameToImage}
                    onTeamSelected={handleTeamSelected}
                  />
                ))}
                <div style={{ height: '100px' }}></div>
              </InfiniteScroll>
            </div>
          ) : (
            <Empty
              className="empty-container"
              style={{
                height: isTablet
                  ? 'calc(100vh - 201px)'
                  : isMobile
                  ? 'calc(100vh - 305.14px)'
                  : 'calc(100vh - 150px)',
              }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No results. Filter Tournaments to see results."
            />
          )}
        </>
      ) : (
        <div style={{ padding: '16px' }}>
          <Skeleton avatar paragraph={{ rows: 1 }} active />
        </div>
      )}
    </>
  )
}
