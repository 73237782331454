import { Match, MatchWithName, Team } from "../models/types";

export const createMatchWithName = (match: Match, teamIdToTeamMap?: Map<string, Team>): MatchWithName => {
	return {
		...match,
		blueTeamName: getBlueTeamName(match, teamIdToTeamMap),
		redTeamName: getRedTeamName(match, teamIdToTeamMap)
	} as MatchWithName
}

export const getBlueTeamName = (match: Match, teamIdToTeamMap?: Map<string, Team>): string => {
	return teamIdToTeamMap?.get(match.blueTeam)?.name || match.blueTeam
}

export const getRedTeamName = (match: Match, teamIdToTeamMap?: Map<string, Team>): string => {
	return teamIdToTeamMap?.get(match.redTeam)?.name || match.redTeam
}