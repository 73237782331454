import { CalendarEvent, MatchWithName } from '../models/types'
import { utcDateStringToDateObject } from './date'

const createEventDescription = (match: MatchWithName, blueTeamName: string, redTeamName: string) => {
  return `LoL Esports Reminder ${
    match.isFlexibleStart === '1'
      ? '\nWarning: This notification was generated when the start date was unconfirmed. This notification may have an inaccurate start time.'
      : ''
  }\n\nMatch: ${blueTeamName} vs. ${redTeamName}\nTournament: ${
    match.tournamentName
  } ${match.week} Day ${match.matchDay}\nStream: ${match.stream}\nBest of: ${
    match.bestOf
  }\n\nGenerate more reminders at https://lolesportsreminder.com`
}

const createCalendarEvent = (
  match: MatchWithName,
  reminderMinutes?: number
): CalendarEvent | null => {
  try {
    const tournamentTitle = "[" + match.tournamentName.split(" ")[0] + "]"
    const matchDate = utcDateStringToDateObject(match.date)
    const bestOf = Number(match.bestOf)
    const blueTeamName = match.blueTeamName
    const redTeamName = match.redTeamName
    return {
      summary: tournamentTitle + " " + blueTeamName + ' vs. ' + redTeamName,
      description: createEventDescription(match, blueTeamName, redTeamName),
      location: match.stream,
      reminder: reminderMinutes,
      start: {
        dateTime: matchDate,
        timeZone: 'UTC',
      },
      end: {
        dateTime: new Date(matchDate.getTime() + bestOf * 3600000),
        timeZone: 'UTC',
      },
    } as CalendarEvent
  } catch (e: any) {
    console.error(e)
    return null
  }
}

const createCalendarEventWithMatchWithName = (
  match: MatchWithName,
  reminderMinutes?: number
): CalendarEvent | null => {
  try {
    const tournamentTitle = "[" + match.tournamentName.split(" ")[0] + "]"
    const matchDate = utcDateStringToDateObject(match.date)
    const bestOf = Number(match.bestOf)
    const blueTeamName = match.blueTeamName
    const redTeamName = match.redTeamName
    return {
      summary: tournamentTitle + " " + blueTeamName + ' vs. ' + redTeamName,
      description: createEventDescription(match, blueTeamName, redTeamName),
      location: match.stream,
      reminder: reminderMinutes,
      start: {
        dateTime: matchDate,
        timeZone: 'UTC',
      },
      end: {
        dateTime: new Date(matchDate.getTime() + bestOf * 3600000),
        timeZone: 'UTC',
      },
    } as CalendarEvent
  } catch (e: any) {
    console.error(e)
    return null
  }
}

export const createCalendarEventsList = (
  matches: MatchWithName[],
  reminderMinutes?: number
): (CalendarEvent | null)[] => {
  return matches.map((match: MatchWithName) =>
    createCalendarEvent(match, reminderMinutes)
  )
}

export const createCalendarEventsListWithMatchWithName = (
  matches: MatchWithName[],
  reminderMinutes?: number
): (CalendarEvent | null)[] => {
  return matches.map((match: MatchWithName) =>
    createCalendarEventWithMatchWithName(match, reminderMinutes)
  )
}


export const exportEvents = async (
  calendarEvents: (CalendarEvent | null)[],
  createEventFn: (event: CalendarEvent) => Promise<any>
): Promise<any[]> => {
  const resultArr: any[] = []
  for (var calendarEvent of calendarEvents) {
    try {
      if (calendarEvent === null) throw Error('Event creation error')
      const res: any = await createEventFn(calendarEvent)
      resultArr.push(res)
    } catch (e: any) {
      resultArr.push('EXPORT_ERROR')
      console.error(e)
    }
  }
  return Promise.resolve(resultArr)
}
