import React, { useMemo } from 'react'
import { Card, Col, Divider, List, Popover, Row, Typography } from 'antd'
import {
  ExclamationCircleFilled,
  CheckSquareOutlined,
  BorderOutlined,
} from '@ant-design/icons'
import { Match, Team } from '../../../models/types'
import { formatUtcDateToLocaleDate, utcDateStringToDateObject } from '../../../utils/date'
import { useXSMobile } from '../../../services/hooks/layout.hooks'
import {
  getBlueTeamName,
  getRedTeamName,
} from '../../../utils/match'

const { Text, Title } = Typography

const classes = {
  centeredBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftAlignedBox: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    textAlign: 'left',
  } as React.CSSProperties,
  rightAlignedBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  } as React.CSSProperties,
  flexSpaceAround: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
  } as React.CSSProperties,
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: 80,
  },
  mobileAdditionalInfo: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
  } as React.CSSProperties,
  warningIconColour: { color: '#faad14' },
  warningIconDateContainer: {
    display: 'flex',
    gap: '4px',
  },
  actions: { fontSize: '24px' },
  matchTeamName: {
    display: 'inline-block',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  } as React.CSSProperties,
  mobileLogoAndName: {
    fontSize: '12px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as React.CSSProperties,
}

const containsDay = (week: string) => {
  return week.includes('Day')
}

const FormattedDate = ({ match }: { match: Match }) => {
  const localDate: Date = utcDateStringToDateObject(match.date)
  const { time } = formatUtcDateToLocaleDate(localDate)

  return (
    <div style={classes.flexSpaceAround}>
      <div style={classes.warningIconDateContainer}>
        {match.isFlexibleStart === '1' && (
          <Popover
            content="This match has a flexible start time and it may be changed prior to the start of the match."
            title="Flexible Schedule Warning"
          >
            <div style={classes.warningIconColour}>
              <ExclamationCircleFilled />
            </div>
          </Popover>
        )}
        <Text>{time}</Text>
      </div>
      <Text>
        {containsDay(match.week)
          ? `${match.week}`
          : `${match.week} Day ${match.matchDay}`}
      </Text>
    </div>
  )
}

const AdditionalInfo = ({ match }: { match: Match }) => {
  return (
    <>
      <div style={classes.flexSpaceAround}>
        <Text>{match.tournamentName}</Text>
        <Text>Best of {match.bestOf}</Text>
      </div>
    </>
  )
}

const MobileAdditionalInfo = ({ match }: { match: Match }) => {
  const localDate: Date = utcDateStringToDateObject(match.date)
  const { time } = formatUtcDateToLocaleDate(localDate)
  const tourneyNameSplit = match.tournamentName.split(' ')
  return (
    <div style={classes.mobileAdditionalInfo}>
      <div style={classes.warningIconDateContainer}>
        {match.isFlexibleStart === '1' && (
          <Popover
            content="This match has a flexible start time and it may be changed prior to the start of the match."
            title="Flexible Schedule Warning"
          >
            <div style={classes.warningIconColour}>
              <ExclamationCircleFilled />
            </div>
          </Popover>
        )}
        <Text>{time}</Text>
      </div>
      <Text style={{ marginBottom: '8px' }}>
        {containsDay(match.week)
          ? `${match.week}`
          : `${match.week} Day ${match.matchDay}`}
      </Text>
      <Text>
        {tourneyNameSplit[0]} {tourneyNameSplit[1]}
      </Text>
      <Text>BO{match.bestOf}</Text>
    </div>
  )
}

interface MatchProps {
  match: Match
  onMatchSelected: (match: Match) => void
  teamIdToTeamMap?: Map<string, Team>
  boxChecked: boolean
  blueTeamImage?: string
  redTeamImage?: string
}

export const MatchComponent = (matchProps: MatchProps) => {
  const isMobile = useXSMobile()
  return (
    <>
      {isMobile ? (
        <MobileMatch {...matchProps} />
      ) : (
        <DesktopMatch {...matchProps} />
      )}
    </>
  )
}

const MobileMatch = ({
  match,
  onMatchSelected,
  boxChecked,
  teamIdToTeamMap,
  blueTeamImage,
  redTeamImage,
}: MatchProps) => {
  const teamNames: { blueTeamName: string; redTeamName: string } =
    useMemo(() => {
      return {
        blueTeamName: getBlueTeamName(match, teamIdToTeamMap),
        redTeamName: getRedTeamName(match, teamIdToTeamMap),
      }
    }, [match, teamIdToTeamMap])

  return (
    <>
      <Card
        key={match.id}
        hoverable
        bordered={false}
        className="tournament-list-ant-card-body"
      >
        <List.Item
          onClick={() => onMatchSelected(match)}
          actions={[
            boxChecked ? (
              <CheckSquareOutlined
                style={{ ...classes.actions, color: 'green' }}
              />
            ) : (
              <BorderOutlined
                style={{
                  ...classes.actions,
                  color: 'rgba(140, 140, 140, 0.35)',
                }}
              />
            ),
          ]}
        >
          <Row justify="center" style={{ width: '100%' }}>
            <Col span={6}>
              <MobileAdditionalInfo match={match} />
            </Col>
            <Col span={18} style={classes.centeredBox}>
              <Row justify="center" style={{ width: '100%' }}>
                <Col span={10} style={classes.mobileLogoAndName}>
                  <img
                    src={blueTeamImage}
                    alt={'logoimage'}
                    style={classes.image}
                  />
                  <Text strong style={classes.matchTeamName}>
                    {teamNames.blueTeamName}
                  </Text>
                </Col>
                <Col span={4} style={classes.centeredBox}>
                  <Text>vs.</Text>
                </Col>
                <Col span={10} style={classes.mobileLogoAndName}>
                  <img
                    src={redTeamImage}
                    alt={'logoimage'}
                    style={classes.image}
                  />
                  <Text strong style={classes.matchTeamName}>
                    {teamNames.redTeamName}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </List.Item>
      </Card>
      <Divider className="divider" />
    </>
  )
}

const DesktopMatch = ({
  match,
  onMatchSelected,
  boxChecked,
  teamIdToTeamMap,
  blueTeamImage,
  redTeamImage,
}: MatchProps) => {
  const teamNames: { blueTeamName: string; redTeamName: string } =
    useMemo(() => {
      return {
        blueTeamName: getBlueTeamName(match, teamIdToTeamMap),
        redTeamName: getRedTeamName(match, teamIdToTeamMap),
      }
    }, [match, teamIdToTeamMap])

  return (
    <>
      <Card
        key={match.id}
        hoverable
        bordered={false}
        className="tournament-list-ant-card-body"
      >
        <List.Item
          onClick={() => onMatchSelected(match)}
          actions={[
            boxChecked ? (
              <CheckSquareOutlined
                style={{ ...classes.actions, color: 'green' }}
              />
            ) : (
              <BorderOutlined
                style={{
                  ...classes.actions,
                  color: 'rgba(140, 140, 140, 0.35)',
                }}
              />
            ),
          ]}
        >
          <Row justify="center" style={{ width: '100%' }}>
            <Col span={4}>
              <FormattedDate match={match} />
            </Col>
            <Col span={16} style={classes.centeredBox}>
              <Row justify="center" style={{ width: '100%' }} gutter={12}>
                <Col span={6} style={classes.rightAlignedBox}>
                  <Title level={5} style={classes.matchTeamName}>
                    {teamNames.blueTeamName}
                  </Title>
                </Col>
                <Col span={5} style={classes.rightAlignedBox}>
                  <img
                    src={blueTeamImage}
                    alt={'logoimage'}
                    style={classes.image}
                  />
                </Col>
                <Col span={2} style={classes.centeredBox}>
                  <Text>vs.</Text>
                </Col>
                <Col span={5} style={classes.leftAlignedBox}>
                  <img
                    src={redTeamImage}
                    alt={'logoimage'}
                    style={classes.image}
                  />
                </Col>
                <Col span={6} style={classes.leftAlignedBox}>
                  <Title level={5} style={classes.matchTeamName}>
                    {teamNames.redTeamName}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <AdditionalInfo match={match} />
            </Col>
          </Row>
        </List.Item>
      </Card>
      <Divider className="divider" />
    </>
  )
}
