import React from 'react'
import { Card, Checkbox, Divider, List, Space, Typography } from 'antd'
import image from '../../../assets/icons/Icon-BG-2.jpg'
import { LogoResponse, Team } from '../../../models/types'
import { getLogo } from '../../../utils/image'

const { Title, Text } = Typography

interface TeamsForTournamentProps {
  teamList: Team[]
  tournamentName: string
  selectedTeamIds?: Set<string>
  isTeamImagesLoading: boolean
  mapTeamNameToImage?: LogoResponse
  onTeamSelected: (teamId: string) => void
}
export const TeamsForTournament = ({
  teamList = [],
  tournamentName,
  selectedTeamIds,
  isTeamImagesLoading,
  mapTeamNameToImage,
  onTeamSelected,
}: TeamsForTournamentProps) => {
  return (
    <>
      <Title level={5} style={{ marginTop: '16px' }}>
        {tournamentName}
      </Title>
      <List
        itemLayout="horizontal"
        grid={{
          column: 1,
        }}
        dataSource={teamList.map((team: Team) => team.id)}
        renderItem={(teamId: string, idx: number) => (
          <Card
            hoverable
            bordered={false}
            className="tournament-list-ant-card-body"
          >
            <List.Item
              onClick={() => onTeamSelected(teamId)}
              actions={[
                <Checkbox
                  checked={selectedTeamIds && selectedTeamIds.has(teamId)}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <img
                    src={
                      getLogo(teamId, mapTeamNameToImage, image, isTeamImagesLoading)
                    }
                    alt="lol-esports-calendar-logo"
                    style={{ maxWidth: 80, maxHeight: 80 }}
                  />
                }
                style={
                  selectedTeamIds && !selectedTeamIds.has(teamId)
                    ? { opacity: 0.65 }
                    : {}
                }
                title={teamList[idx].name}
                description={
                  <Space direction="vertical">
                    <Text>{tournamentName}</Text>
                  </Space>
                }
              />
            </List.Item>
          </Card>
        )}
      />
      <Divider className="divider" />
    </>
  )
}
