import { useQuery } from 'react-query'
import { TeamsResponse } from '../../models/types'

const fetchTeams = async (): Promise<TeamsResponse> => {
  try {
    const res = await fetch(process.env.REACT_APP_API_URL + '/api/teams')
    if (!res.ok) throw Error('Error in /api/teams')
    return res.json()
  } catch (e: any) {
    console.error(e)
    return Promise.reject('Error in /api/teams')
  }
}

export const useApiGetTeams = () => {
  return useQuery('teams', fetchTeams, {
    staleTime: Infinity,
  })
}
