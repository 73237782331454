import { LogoResponse } from '../models/types'

export const getLogo = (
  key: string,
  imageMap: LogoResponse | undefined,
  fallbackImage: any,
  loading: boolean
) => {
  return !loading && imageMap && imageMap.data && imageMap.data.hasOwnProperty(key)
    ? imageMap.data[key]
    : fallbackImage
}
