import React, { useState } from 'react'
import { Alert, Button, Input, Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ExportStatus } from '../../../models/enums'
import { MatchWithName } from '../../../models/types'
import { postSMS } from '../../../services/api/sms.api'
import { createSMSEventsList } from '../../../utils/sms'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useMobile } from '../../../services/hooks/layout.hooks'
import { DESKTOP_DRAWER_CONTENT_WIDTH } from '../../../models/constants'
import { useGoogleAnalytics } from '../../../services/hooks/google-analytics.hooks'

const { Title, Text } = Typography

interface SMSExportProps {
  selectedMatchWithNames: MatchWithName[]
}

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '16px',
    height: '100%',
    padding: '16px',
  } as React.CSSProperties,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '16px',
  } as React.CSSProperties,
  button: {
    width: 'auto',
    minHeight: '50px',
  },
  reminderInput: {
    marginTop: '8px',
  },
  generateButton: {
    width: 'auto',
    minHeight: '50px',
    fontWeight: 'bold',
  },
  generateAgainButton: {
    width: 'auto',
    minHeight: '50px',
  },
  disabledGoogleButton: {
    backgroundColor: 'rgba(37, 5, 5, .08)',
    color: 'rgba(0, 0, 0, .40)',
    cursor: 'not-allowed',
    boxShadow: 'rgb(0, 0, 0, 25%) 0px 2px 4px 0px',
    width: 'auto',
    minHeight: '50px',
  },
}

export const SMSExport = ({ selectedMatchWithNames }: SMSExportProps) => {
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>()
  const [reminder, setReminder] = useState<string>('15')
  const [exportStatus, setExportStatus] = useState<ExportStatus>(
    ExportStatus.NONE
  )

  const createAndExportEvents = async (matches: MatchWithName[]) => {
    try {
      setExportStatus(ExportStatus.LOADING)
      if (!phoneNumber) throw Error('No phone number')

      const SMSEventArr = createSMSEventsList(matches, Number(reminder))
      await postSMS(SMSEventArr, phoneNumber)
      setExportStatus(ExportStatus.SUCCESS)
    } catch (e: any) {
      setExportStatus(ExportStatus.FAILED)
      console.error(e.message)
    }
  }

  const handleCreateEvents = () => {
    try {
      googleAnalytics.log('button.clicked_export.generate.sms')
      createAndExportEvents(selectedMatchWithNames)
    } catch (e: any) {
      setExportStatus(ExportStatus.FAILED)
      console.error(e)
    }
  }

  const clearSelections = () => {
    setExportStatus(ExportStatus.NONE)
    setPhoneNumber(undefined)
  }

  return (
    <>
      <div style={classes.root}>
        <div style={classes.content}>
          <div
            style={{
              maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined,
            }}
          >
            <Title level={3}>Set SMS Notifications</Title>
            <Alert
              style={{
                maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined,
              }}
              showIcon
              type="warning"
              message="SMS Reminder Restrictions"
              description="You can only create SMS reminders for matches more than 15
              minutes and less than 35 days prior to a match (Event time + reminder
              time)"
            />
          </div>

          <div style={{ marginTop: '16px', marginBottom: '16px' }}>
            <Text
              disabled={
                exportStatus === ExportStatus.LOADING ||
                exportStatus === ExportStatus.SUCCESS
              }
            >
              Reminder time (in minutes)
            </Text>
            <Input
              type="number"
              value={reminder}
              disabled={
                exportStatus === ExportStatus.LOADING ||
                exportStatus === ExportStatus.SUCCESS
              }
              onChange={(e) => setReminder(e.target.value)}
              style={classes.reminderInput}
            />
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div style={{ marginBottom: '8px' }}>
              <Text
                disabled={
                  exportStatus === ExportStatus.LOADING ||
                  exportStatus === ExportStatus.SUCCESS
                }
              >
                Phone number
              </Text>
            </div>

            <PhoneInput
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry="US"
              disabled={
                exportStatus === ExportStatus.LOADING ||
                exportStatus === ExportStatus.SUCCESS
              }
            />
          </div>

          {exportStatus === ExportStatus.LOADING && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}
          {exportStatus === ExportStatus.SUCCESS && (
            <Alert
              style={{
                maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined,
              }}
              message="Export Successful"
              description="Your SMS reminders have been scheduled."
              type="success"
              showIcon
            />
          )}
          {exportStatus === ExportStatus.FAILED && (
            <Alert
              style={{
                maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined,
              }}
              message="Error"
              description={
                <>
                  <p>
                    Looks like there was an issue scheduling your SMS reminders
                    for 1 or more of the matches.
                  </p>
                  <p>
                    Remember that you can not create an SMS reminder less than 15
                    minutes and over 35 days prior to a match, including reminder
                    time.
                  </p>
                  <p>
                    If you do not believe this is the case for ANY of the
                    matches, please wait a bit and try again.
                  </p>
                </>
              }
              type="error"
              showIcon
            />
          )}
        </div>
        {exportStatus === ExportStatus.SUCCESS ? (
          <Button style={classes.generateAgainButton} onClick={clearSelections}>
            Generate for another phone number
          </Button>
        ) : (
          <Button
            type="primary"
            danger
            style={classes.generateButton}
            disabled={exportStatus === ExportStatus.LOADING || !phoneNumber}
            onClick={handleCreateEvents}
          >
            Generate and Set Reminders
          </Button>
        )}
      </div>
    </>
  )
}
