import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MatchesList } from '../../components/MatchesList/MatchesList'
import { TournamentList } from '../../components/TournamentList/TournamentList'
import { useApiGetMatches } from '../../services/api/matches.api'
import { useApiGetTournaments } from '../../services/api/tournaments.api'
import { Col, Row } from 'antd'
import { TeamsList } from '../../components/TeamsList/TeamsList'
import { useApiGetTeamLogos, useApiGetTournamentLogos } from '../../services/api/images.api'
import { Confirmation } from '../../components/Confirmation/Confirmation'
import { Match, Team, Tournament } from '../../models/types'
import { Export } from '../../components/Export/Export'
import { FilterHeader } from '../../components/FilterHeader/FilterHeader'
import { FilterWrapper } from '../../components/FilterWrapper/FilterWrapper'
import { useTablet, useMobile } from '../../services/hooks/layout.hooks'
import { FilterTab, HeaderAction } from '../../models/enums'
import { Header } from '../../components/Header/Header'
import { useGoogleAnalytics } from '../../services/hooks/google-analytics.hooks'
import { useApiGetTeams } from '../../services/api/teams.api'

export const FrontPage = () => {
  const isTablet = useTablet()
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()

  const [currentFilterTab, setCurrentFilterTab] = useState<FilterTab>(
    FilterTab.TOURNAMENT
  )

  // User selections
  const [selectedTournamentIds, setSelectedTournamentIds] =
    useState<Set<string>>()
  const [selectedTeamIds, setSelectedTeamIds] = useState<Set<string>>()
  const [selectedMatchIdsMappedToMatch, setSelectedMatchIdsMappedToMatch] =
    useState<Map<string, Match>>(new Map<string, Match>())

  // Raw teams & tournaments API data
  const { data: tournamentData, isLoading: isTournamentLoading } =
    useApiGetTournaments()
  const { data: teamData, isLoading: isTeamLoading } = useApiGetTeams()

  const isInitialLoad = useRef<boolean>(true)
  const isInitialTeamLoad = useRef<boolean>(true)

  const [showConfirmationDrawer, setShowConfirmationDrawer] =
    useState<boolean>(false)
  const [showExportDrawer, setShowExportDrawer] = useState<boolean>(false)
  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false)

  // Parse tournament data to create tournament state
  const tournamentIdToTournamentMap: Map<string, Tournament> | undefined =
    useMemo(() => {
      if (tournamentData) {
        const map = new Map<string, Tournament>()
        for (const tournament of tournamentData.data) {
          map.set(tournament.id, tournament)
        }
        return map
      }
    }, [tournamentData])

  // Parse team data to create team state + filter depending on selected tournaments
  const teamIdToTeamMap: Map<string, Team> | undefined = useMemo(() => {
    if (teamData && tournamentIdToTournamentMap && selectedTournamentIds) {
      const teamidsFromSelectedTournaments: Set<string> = new Set(
        Array.from(selectedTournamentIds)
          .filter((tournamentId: string) =>
            tournamentIdToTournamentMap.has(tournamentId)
          )
          .map((tournamentId: string) =>
            tournamentIdToTournamentMap.get(tournamentId)
          )
          .filter((tournament) => !!tournament)
          .flatMap((tournament) => tournament!.teamIds)
      )

      return new Map(
        teamData.data
          .filter((team) => teamidsFromSelectedTournaments.has(team.id))
          .map((team) => [team.id, team])
      )
    }
  }, [teamData, selectedTournamentIds, tournamentIdToTournamentMap])

  const getMatchesApiTournamentKey = useMemo(() => {
    return (
      tournamentIdToTournamentMap &&
      Array.from(selectedTournamentIds || [])
        .filter((id) => tournamentIdToTournamentMap.has(id))
        .map((id) => tournamentIdToTournamentMap!.get(id)!.truncatedId)
    )
  }, [selectedTournamentIds, tournamentIdToTournamentMap])

  const getMatchesApiTeamKey = useMemo(() => {
    return (
      teamIdToTeamMap &&
      Array.from(selectedTeamIds || [])
        .filter((id) => teamIdToTeamMap.has(id))
        .map((id) => teamIdToTeamMap.get(id)!.truncatedId)
    )
  }, [selectedTeamIds, teamIdToTeamMap])

  // Raw Matches API data, relies on tournaments & teams
  const {
    data: matchData,
    isLoading: isMatchLoading,
    fetchNextPage,
    hasNextPage,
  } = useApiGetMatches(getMatchesApiTournamentKey, getMatchesApiTeamKey)

  // Parse match data to create match state + filter depending on selected teams
  const matchList: Match[] = useMemo(() => {
    return matchData
      ? matchData.pages.flatMap((matchResponse) => matchResponse?.data ?? [])
      : []
  }, [matchData])

  // Images
  const { data: teamImageMap, isLoading: isTeamImagesLoading } = useApiGetTeamLogos()
  const { data: tournamentImageMap, isLoading: isTournamentImagesLoading } = useApiGetTournamentLogos()

  // Calculates/Memoizes default selected tournaments
  const defaultSelectedTournaments = useMemo(() => {
    if (tournamentIdToTournamentMap) {
      const defaultSelectedTournaments = new Set<string>()

      tournamentIdToTournamentMap.forEach((tournament: Tournament) => {
        if (tournament.isFeatured) {
          defaultSelectedTournaments.add(tournament.id)
        }
      })
      return defaultSelectedTournaments
    }
  }, [tournamentIdToTournamentMap])

  // set default selected tournaments
  useEffect(() => {
    if (
      tournamentIdToTournamentMap &&
      defaultSelectedTournaments &&
      isInitialLoad.current
    ) {
      setSelectedTournamentIds(defaultSelectedTournaments)
      isInitialLoad.current = false
    }
  }, [tournamentIdToTournamentMap, defaultSelectedTournaments])

  // set default selected teams
  useEffect(() => {
    if (teamIdToTeamMap && isInitialTeamLoad.current) {
      setSelectedTeamIds(new Set(teamIdToTeamMap.keys()))
      isInitialTeamLoad.current = false
    }
  }, [teamIdToTeamMap])

  const handleConfirmationNext = () => {
    googleAnalytics.log('button.clicked_confirmation.next')
    setShowConfirmationDrawer(false)
    setShowExportDrawer(true)
  }

  const handleExportBack = () => {
    setShowConfirmationDrawer(true)
    setShowExportDrawer(false)
  }

  const handleSelectAllTournaments = () => {
    googleAnalytics.log('button.clicked_selectall.tournaments')
    setSelectedTournamentIds(new Set(tournamentIdToTournamentMap?.keys()))
  }

  const handleClearAllTournaments = () => {
    googleAnalytics.log('button.clicked_clearall.tournaments')
    setSelectedTournamentIds(new Set<string>())
  }

  const handleSelectAllTeams = () => {
    googleAnalytics.log('button.clicked_selectall.teams')
    setSelectedTeamIds(new Set(teamIdToTeamMap?.keys()))
  }

  const handleClearAllTeams = () => {
    googleAnalytics.log('button.clicked_clearall.teams')
    setSelectedTeamIds(new Set<string>())
  }

  const handleSelectAllMatches = () => {
    googleAnalytics.log('button.clicked_selectall.matches')
    setSelectedMatchIdsMappedToMatch(
      new Map(matchList?.map((val: Match) => [val.id, val]))
    )
  }

  const handleClearAllMatches = () => {
    googleAnalytics.log('button.clicked_clearall.matches')
    setSelectedMatchIdsMappedToMatch(new Map<string, Match>())
  }

  const handleTabChange = (tab: FilterTab) => {
    setCurrentFilterTab(tab)
  }

  const handleMobileFilter = () => {
    if (!showFilterDrawer) {
      googleAnalytics.log('page.view_mobile.filter')
    }
    setShowFilterDrawer(!showFilterDrawer)
  }

  return (
    <>
      <Header
        headerAction={HeaderAction.FRONT}
        openConfirmationDrawer={() => setShowConfirmationDrawer(true)}
        numMatchesFiltered={selectedMatchIdsMappedToMatch.size}
      />
      <FilterHeader
        onSelectAllTournaments={handleSelectAllTournaments}
        onClearAllTournaments={handleClearAllTournaments}
        onSelectAllTeams={handleSelectAllTeams}
        onClearAllTeams={handleClearAllTeams}
        onSelectAllMatches={handleSelectAllMatches}
        onClearAllMatches={handleClearAllMatches}
        onFilterTabChange={handleTabChange}
        openConfirmationDrawer={() => setShowConfirmationDrawer(true)}
        numMatchesFiltered={selectedMatchIdsMappedToMatch.size}
        onMobileFilter={handleMobileFilter}
      />
      <Row>
        <FilterWrapper
          currentTab={currentFilterTab}
          tournamentList={
            <TournamentList
              isLoading={isTournamentLoading || isInitialLoad.current}
              tournamentIdToTournamentMap={tournamentIdToTournamentMap}
              selectedTournamentIds={selectedTournamentIds}
              setSelectedTournamentIds={setSelectedTournamentIds}
              isTournamentImagesLoading={isTournamentImagesLoading}
              mapTournamentIdToImage={tournamentImageMap}
            />
          }
          teamsList={
            <TeamsList
              isLoading={isTeamLoading || isInitialTeamLoad.current}
              teamIdToTeamMap={teamIdToTeamMap}
              selectedTeamIds={selectedTeamIds}
              setSelectedTeamIds={setSelectedTeamIds}
              isTeamImagesLoading={isTeamImagesLoading}
              mapTeamNameToImage={teamImageMap}
            />
          }
          showFilterDrawer={showFilterDrawer}
          onMobileFilter={handleMobileFilter}
          onSelectAllTournaments={handleSelectAllTournaments}
          onClearAllTournaments={handleClearAllTournaments}
          onSelectAllTeams={handleSelectAllTeams}
          onClearAllTeams={handleClearAllTeams}
        />
        <Col span={isTablet ? 16 : isMobile ? 24 : 12}>
          <MatchesList
            isLoading={isMatchLoading || isTournamentLoading || isTeamLoading}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            matchList={matchList}
            teamIdToTeamMap={teamIdToTeamMap}
            mapSelectedMatchIdToMatch={selectedMatchIdsMappedToMatch}
            setMapSelectedMatchIdToMatch={setSelectedMatchIdsMappedToMatch}
            isTeamImagesLoading={isTeamImagesLoading}
            mapTeamNameToImage={teamImageMap}
            onMobileFilter={handleMobileFilter}
          />
        </Col>
        <Confirmation
          mapSelectedMatchIdToMatch={selectedMatchIdsMappedToMatch}
          teamIdToTeamMap={teamIdToTeamMap}
          setMapSelectedMatchIdToMatch={setSelectedMatchIdsMappedToMatch}
          showConfirmationDrawer={showConfirmationDrawer}
          onClose={() => setShowConfirmationDrawer(false)}
          onNextButtonClick={handleConfirmationNext}
        />
        <Export
          selectedMatchIdsMappedToMatch={selectedMatchIdsMappedToMatch}
          teamIdToTeamMap={teamIdToTeamMap}
          showExportDrawer={showExportDrawer}
          onBack={handleExportBack}
          onClose={() => setShowExportDrawer(false)}
        />
      </Row>
    </>
  )
}
