import { Match } from '../models/types'

export const formatUtcDateToLocaleDate = (utcDateObject: Date): { date: string; time: string } => {
  // convert timezone before inputting
  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions
  const timeOptions = {
    timeZoneName: 'short',
    hour: 'numeric',
    minute: '2-digit',
  } as Intl.DateTimeFormatOptions

  return {
    date: utcDateObject.toLocaleString(undefined, dateOptions),
    time: utcDateObject.toLocaleTimeString(undefined, timeOptions),
  }
}

export const sortByDate = (match1: Match, match2: Match): number => {
  if (match1.date < match2.date) {
    return -1
  } else if (match1.date > match2.date) {
    return 1
  }
  return 0
}

export const formatLastUpdatedTime = (date: Date): string => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  } as Intl.DateTimeFormatOptions
  return date.toLocaleString(undefined, options).replace(',', '')
}

export const utcDateStringToDateObject = (dateString: string): Date => {
  return new Date(dateString.replace(' ', 'T') + 'Z');
}

