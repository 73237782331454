import { useInfiniteQuery } from 'react-query'
import { MatchesResponse } from '../../models/types'

const fetchMatches = async (
  tournamentTruncatedIds: string[],
  teamTruncatedIds: string[],
  nextPageToken?: string
): Promise<MatchesResponse | undefined> => {
  try {
    if (tournamentTruncatedIds.length === 0 || teamTruncatedIds.length === 0) {
      return Promise.resolve(undefined)
    }
    const queryParams = {
      tournaments: tournamentTruncatedIds.join(','),
      teams: teamTruncatedIds.join(','),
      ...(nextPageToken && { pageToken: nextPageToken }),
    }
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/matches?${new URLSearchParams(
        queryParams
      )}`
    )

    if (!res.ok) throw Error('Error in /api/matches')
    return res.json()
  } catch (e: any) {
    console.error(e)
    return Promise.reject('Error in /api/matches')
  }
}

export const useApiGetMatches = (
  tournamentTruncatedIds?: string[],
  teamTruncatedIds?: string[]
) => {
  const tournamentIdsArray = [...(tournamentTruncatedIds || [])].sort()
  const teamIdsArray = [...(teamTruncatedIds || [])].sort()
  return useInfiniteQuery(
    ['matches', tournamentIdsArray, teamIdsArray],
    ({ pageParam }) =>
      fetchMatches(tournamentIdsArray, teamIdsArray, pageParam),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.hasMore ? lastPage.nextPageToken : undefined,
      staleTime: Infinity,
      enabled: !!(tournamentTruncatedIds && teamTruncatedIds),
    }
  )
}
