import React, { useMemo } from 'react'
import { Button, Divider, Empty, Skeleton, Typography } from 'antd'
import { Match, Team } from '../../models/types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMobile, useTablet } from '../../services/hooks/layout.hooks'
import { formatUtcDateToLocaleDate, utcDateStringToDateObject } from '../../utils/date'
import { MatchComponent } from './components/Match'
import image from '../../assets/icons/Icon-BG-2.jpg'
import { getLogo } from '../../utils/image'

const { Title } = Typography

interface MatchesListProps {
  isLoading: boolean
  hasNextPage?: boolean
  fetchNextPage: () => any
  matchList?: Match[]
  mapSelectedMatchIdToMatch: Map<string, Match>
  teamIdToTeamMap?: Map<string, Team>
  setMapSelectedMatchIdToMatch: (set: Map<string, Match>) => void
  isTeamImagesLoading: boolean
  mapTeamNameToImage?: any
  onMobileFilter: () => void
}

export const MatchesList = ({
  isLoading,
  hasNextPage,
  fetchNextPage,
  matchList,
  mapSelectedMatchIdToMatch,
  teamIdToTeamMap,
  setMapSelectedMatchIdToMatch,
  isTeamImagesLoading,
  mapTeamNameToImage,
  onMobileFilter,
}: MatchesListProps) => {
  const isMobile = useMobile()
  const isTablet = useTablet()

  const showTitleById: Set<string> = useMemo(() => {
    const matchIdsToShowTitle = new Set<string>()
    const visitedDates = new Set<string>()
    if (matchList) {
      for (const match of matchList) {
        const utcDateObject = utcDateStringToDateObject(match.date)
        const matchDate = formatUtcDateToLocaleDate(utcDateObject).date
        if (!visitedDates.has(matchDate)) {
          matchIdsToShowTitle.add(match.id)
        }
        visitedDates.add(matchDate)
      }
    }
    return matchIdsToShowTitle
  }, [matchList])

  const handleMatchSelected = (match: Match): void => {
    if (!mapSelectedMatchIdToMatch.has(match.id)) {
      setMapSelectedMatchIdToMatch(
        new Map<string, Match>(mapSelectedMatchIdToMatch.set(match.id, match))
      )
    } else {
      const filteredMapStateArr = Array.from(mapSelectedMatchIdToMatch).filter(
        (val) => val[0] !== match.id
      )
      setMapSelectedMatchIdToMatch(new Map<string, Match>(filteredMapStateArr))
    }
  }

  return (
    <>
      {!isLoading ? (
        <>
          {matchList && matchList.length > 0 ? (
            <div
              id="scrollableMatchDiv"
              className={
                isMobile
                  ? 'mobile-match-scroll-section'
                  : isTablet
                  ? 'tablet-scroll-section'
                  : 'scroll-section'
              }
            >
              <InfiniteScroll
                dataLength={matchList ? matchList.length : 0}
                next={fetchNextPage}
                hasMore={!!hasNextPage}
                loader={
                  <div style={{ padding: '16px' }}>
                    <Skeleton avatar paragraph={{ rows: 1 }} active />
                  </div>
                }
                scrollableTarget="scrollableMatchDiv"
              >
                {matchList.map((match: Match) => (
                  <React.Fragment key={match.id}>
                    {showTitleById.has(match.id) && (
                      <>
                        <Title
                          level={5}
                          style={{ padding: '20px 12px', marginBottom: 0 }}
                        >
                          {formatUtcDateToLocaleDate(utcDateStringToDateObject(match.date)).date}
                        </Title>
                        <Divider className="divider" />
                      </>
                    )}
                    <MatchComponent
                      key={match.id}
                      match={match}
                      onMatchSelected={handleMatchSelected}
                      teamIdToTeamMap={teamIdToTeamMap}
                      boxChecked={mapSelectedMatchIdToMatch.has(match.id)}
                      blueTeamImage={
                        getLogo(match.blueTeam, mapTeamNameToImage, image, isTeamImagesLoading)
                      }
                      redTeamImage={
                        getLogo(match.redTeam, mapTeamNameToImage, image, isTeamImagesLoading)
                      }
                    />
                  </React.Fragment>
                ))}
              </InfiniteScroll>
            </div>
          ) : (
            <Empty
              className="empty-container-matches"
              style={{
                height: isMobile
                  ? 'calc(100vh - 154px)'
                  : isTablet
                  ? 'calc(100vh - 201px)'
                  : 'calc(100vh - 150px)',
              }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No results. Filter Tournaments and Teams to see results."
            >
              {isMobile && (
                <Button type="primary" onClick={onMobileFilter}>
                  Filter
                </Button>
              )}
            </Empty>
          )}
        </>
      ) : (
        <div style={{ padding: '16px' }}>
          <Skeleton avatar paragraph={{ rows: 5 }} active />
        </div>
      )}
    </>
  )
}
