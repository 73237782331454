import { MatchWithName, SMSEvent } from '../models/types'
import { utcDateStringToDateObject } from './date'

const createEventDescription = (
  match: MatchWithName,
  reminderMinutes: number
) => {
  const blueTeamName = match.blueTeamName
  const redTeamName = match.redTeamName

  return `LoL Esports Reminder ${
    match.isFlexibleStart === '1'
      ? '\nWarning: This notification was generated when the start date was unconfirmed. This notification may have an inaccurate start time.'
      : ''
  }\n\nMatch: ${blueTeamName} vs. ${redTeamName}\nStarts in: ${reminderMinutes} minutes\nTournament: ${
    match.tournamentName
  } ${match.week} Day ${match.matchDay}\nStream: ${match.stream}\nBest of: ${
    match.bestOf
  }\n\nGenerate more reminders at https://lolesportsreminder.com`
}

const createSMSEvent = (
  match: MatchWithName,
  reminderMinutes: number
): SMSEvent | null => {
  try {
    const matchDate = utcDateStringToDateObject(match.date)
    const reminderDate = new Date(matchDate.getTime() - reminderMinutes * 60000)
    return {
      body: createEventDescription(match, reminderMinutes),
      date: reminderDate.toISOString(),
    }
  } catch (e: any) {
    console.error(e)
    return null
  }
}

export const createSMSEventsList = (
  matches: MatchWithName[],
  reminderMinutes?: number
): (SMSEvent | null)[] => {
  return matches.map((match: MatchWithName) =>
    createSMSEvent(
      match,
      reminderMinutes ? reminderMinutes : 0
    )
  )
}
