import { useQuery } from 'react-query'
import { LogoResponse } from '../../models/types';

const fetchTournamentLogos = async (): Promise<LogoResponse> => {
  try {
    const res = await fetch(process.env.REACT_APP_API_URL + '/api/tournament-logos')
    if (!res.ok) throw Error('Error in /api/tournament-logos')
    return res.json()
  } catch (e: any) {
    console.error(e)
    return Promise.reject('Error in /api/tournament-logos')
  }
}

const fetchTeamLogos = async (): Promise<LogoResponse> => {
  try {
    const res = await fetch(process.env.REACT_APP_API_URL + '/api/team-logos')
    if (!res.ok) throw Error('Error in /api/team-logos')
    return res.json()
  } catch (e: any) {
    console.error(e)
    return Promise.reject('Error in /api/team-logos')
  }
}


export const useApiGetTournamentLogos = () => {
  return useQuery(['tournament-logos'], () => fetchTournamentLogos(), {
    staleTime: Infinity
  })
}

export const useApiGetTeamLogos = () => {
  return useQuery(['team-logos'], () => fetchTeamLogos(), {
    staleTime: Infinity
  })
}