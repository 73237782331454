import React from 'react'
import { Card, Checkbox, List, Space, Typography } from 'antd'
import { LogoResponse, Tournament } from '../../../models/types'
import image from '../../../assets/icons/Icon-BG-1.jpg'
import { getLogo } from '../../../utils/image'

const { Text } = Typography

interface TournamentsSectionProps {
  tournamentList?: Tournament[]
  selectedTournamentIds?: Set<string>
  isTournamentImagesLoading: boolean
  mapTournamentIdToImage?: LogoResponse
  onTournamentSelected: (tournamentId: string) => void
}

export const TournamentsSection = ({
  tournamentList,
  selectedTournamentIds,
  isTournamentImagesLoading,
  mapTournamentIdToImage,
  onTournamentSelected,
}: TournamentsSectionProps) => {
  return (
    <List
      itemLayout="horizontal"
      grid={{
        column: 1,
      }}
      dataSource={tournamentList}
      renderItem={(item: Tournament) => (
        <>
          <Card
            hoverable
            bordered={false}
            className="tournament-list-ant-card-body"
          >
            <List.Item
              onClick={() => onTournamentSelected(item.id)}
              actions={[
                <Checkbox checked={selectedTournamentIds && selectedTournamentIds.has(item.id)} />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <img
                    src={
                      getLogo(item.id, mapTournamentIdToImage, image, isTournamentImagesLoading)
                    }
                    alt="lol-esports-calendar-logo"
                    style={{ maxWidth: 80, maxHeight: 80 }}
                  />
                }
                style={
                  selectedTournamentIds && !selectedTournamentIds.has(item.id) ? { opacity: 0.65 } : {}
                }
                title={item.name}
                description={
                  <Space direction="vertical">
                    <Text>Start date: {item.startDate}</Text>
                    <Text>End date: {item.endDate}</Text>
                  </Space>
                }
              />
            </List.Item>
          </Card>
        </>
      )}
    />
  )
}
