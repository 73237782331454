import React, { useState } from 'react'
import { Alert, Button, Input, Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ExportStatus } from '../../../models/enums'
import { MatchWithName } from '../../../models/types'
import { createCalendarEventsList, exportEvents } from '../../../utils/calendar'
import GoogleButton from 'react-google-button'
import { useGoogle } from '../../../services/hooks/google.hooks'
import { DESKTOP_DRAWER_CONTENT_WIDTH } from '../../../models/constants'
import { useMobile } from '../../../services/hooks/layout.hooks'
import { useGoogleAnalytics } from '../../../services/hooks/google-analytics.hooks'

const { Title, Text } = Typography

interface GoogleCalendarExportProps {
  selectedMatchWithNames: MatchWithName[]
}

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '16px',
    height: '100%',
    padding: '16px',
  } as React.CSSProperties,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '16px',
  } as React.CSSProperties,
  button: {
    width: 'auto',
    minHeight: '50px',
  },
  reminderInput: {
    marginTop: '8px',
  },
  generateButton: {
    width: 'auto',
    minHeight: '50px',
    fontWeight: 'bold',
  },
  disabledGoogleButton: {
    backgroundColor: 'rgba(37, 5, 5, .08)',
    color: 'rgba(0, 0, 0, .40)',
    cursor: 'not-allowed',
    boxShadow: 'rgb(0, 0, 0, 25%) 0px 2px 4px 0px',
    width: 'auto',
    minHeight: '50px',
  },
}

export const GoogleCalendarExport = ({
  selectedMatchWithNames
}: GoogleCalendarExportProps) => {
  const isMobile = useMobile()
  const googleAnalytics = useGoogleAnalytics()

  const [failedMatchExports, setFailedMatchExports] = useState<MatchWithName[]>([])
  const [exportStatus, setExportStatus] = useState<ExportStatus>(
    ExportStatus.NONE
  )
  const [reminder, setReminder] = useState<string>('15')

  const { isSignedIn, signIn, signOut, createEvent } = useGoogle()

  const createAndExportEvents = (matches: MatchWithName[]) => {
    try {
      if (!isSignedIn) throw Error('User not signed in')

      const calendarEventArr = createCalendarEventsList(
        matches,
        reminder !== '' ? Number(reminder) : undefined
      )
      exportEvents(calendarEventArr, createEvent)
        .then((res) => {
          let idx = 0
          const errorArray: MatchWithName[] = []
          res.forEach(async (val) => {
            if (val === 'EXPORT_ERROR') {
              console.error(
                'Rejected: ' +
                  JSON.stringify(
                    matches[idx].blueTeam + ' vs. ' + matches[idx].redTeam
                  )
              )
              errorArray.push(matches[idx])
            }
            idx++
          })
          setExportStatus(
            errorArray.length === 0 ? ExportStatus.SUCCESS : ExportStatus.FAILED
          )
          setFailedMatchExports(errorArray)
        })
        .catch((e) => {
          console.error(e)
          setExportStatus(ExportStatus.FAILED)
        })
    } catch (e: any) {
      console.error(e)
    }
  }

  const handleCreateEvents = () => {
    googleAnalytics.log('button.clicked_export.generate.google')
    setExportStatus(ExportStatus.LOADING)
    createAndExportEvents(selectedMatchWithNames)
  }

  const handleRetryFailedExports = () => {
    setExportStatus(ExportStatus.LOADING)
    createAndExportEvents(failedMatchExports)
  }

  const handleSignIn = () => {
    googleAnalytics.log('button.clicked_export.signin.google')
    signIn()
  }

  const handleSignOut = () => {
    googleAnalytics.log('button.clicked_export.signout.google')
    signOut()
    setExportStatus(ExportStatus.NONE)
  }

  return (
    <div style={classes.root}>
      <div style={classes.content}>
        <Title level={3}>Add to Google Calendar</Title>
        <GoogleButton
          onClick={handleSignIn}
          disabled={isSignedIn}
          style={isSignedIn ? classes.disabledGoogleButton : classes.button}
        />

        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Text disabled={!isSignedIn || exportStatus !== ExportStatus.NONE}>
            Reminder time (in minutes)
          </Text>
          <Input
            type="number"
            value={reminder}
            disabled={!isSignedIn || exportStatus !== ExportStatus.NONE}
            onChange={(e) => setReminder(e.target.value)}
            style={classes.reminderInput}
          />
        </div>

        {exportStatus === ExportStatus.LOADING && (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        )}
        {exportStatus === ExportStatus.SUCCESS && (
          <Alert
            style={{
              maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined,
            }}
            message="Export Successful"
            description="Your events have now been added to your Google Calendar."
            type="success"
            showIcon
          />
        )}
        {exportStatus === ExportStatus.FAILED && (
          <>
            <Alert
              style={{
                maxWidth: !isMobile ? DESKTOP_DRAWER_CONTENT_WIDTH : undefined,
              }}
              message="Error"
              description="Looks like there was an issue exporting the following matches. Please wait a bit and try again."
              type="error"
              showIcon
            />
            {failedMatchExports.map((match: MatchWithName) => (
              <Text key={match.id}>
                {match.blueTeam} vs. {match.redTeam}
              </Text>
            ))}
            <Button
              type="primary"
              danger
              style={classes.generateButton}
              onClick={handleRetryFailedExports}
            >
              Retry failed exports
            </Button>
          </>
        )}
      </div>
      <Button
        type="primary"
        danger
        style={classes.generateButton}
        disabled={!isSignedIn || exportStatus !== ExportStatus.NONE}
        onClick={handleCreateEvents}
      >
        Generate and Export
      </Button>
      <Button
        disabled={!isSignedIn}
        onClick={handleSignOut}
        style={classes.button}
      >
        Sign out
      </Button>
    </div>
  )
}
