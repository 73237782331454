import { useMutation, useQuery } from 'react-query'
import { MatchWithName, PostShareResponse, ShareResponse } from '../../models/types'

const fetchShare = async (shareId: string): Promise<ShareResponse> => {
  try{
    const res = await fetch(process.env.REACT_APP_API_URL + '/api/share/' + shareId)
    if(!res.ok) throw Error()
    return res.json()
  }catch (e: any){
    console.error(e)
    return Promise.reject("Data doesn't exist for ID: " + shareId)
  }
}

const addShare = async (matches: MatchWithName[]): Promise<PostShareResponse> => {
  try{
    const res = await fetch(process.env.REACT_APP_API_URL + '/api/share', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({matches: matches})
    })
    if(!res.ok) throw Error()
    return res.json()
  }catch(e: any){
    console.error(e)
    return Promise.reject("Error with creating share link")
  }
}

export const useApiGetShare = (shareId: string) => {
  return useQuery(['share', shareId], () => fetchShare(shareId), {
    staleTime: Infinity,
  })
}

export const useApiCreateShare = () => {
  return useMutation(addShare)
}
